import flatten from 'lodash/flatten';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select2 from 'react-select2-wrapper/lib/components/Select2.full';
import { inject, observer } from 'mobx-react';
import { i18n } from '../../../i18n';
import 'react-select2-wrapper/css/select2.css';
import { parseListForSelect2 } from '../utils';
import { submitterType, SUBMITTER_TYPES } from './utils';
import FilterOverlayTrigger from '../FilterOverlayTrigger/component';

const SubmitterFilter = ({ filtersStore, onSubmit }) => {
  const select2Refs = useRef({});
  const select2Config = useRef({});
  const [configInitialized, setConfigInitialized] = useState(false);

  useEffect(() => {
    const updateSelect2Config = () => {
      if (typeof csync !== 'undefined' && csync.AppInit && csync.AppInit.url_builder) {
        const urlBuilder = csync.AppInit.url_builder;

        select2Config.current = {
          [submitterType.USER]: {
            dataUrl: urlBuilder.build('responses', 'possible-submitters'),
            resultsKey: 'possible_users',
          },
          [submitterType.GROUP]: {
            dataUrl: urlBuilder.build('user_groups', 'possible-groups'),
            resultsKey: 'possible_groups',
          },
        };
        setConfigInitialized(true);
      } else {
        // If csync and AppInit are not yet defined, try again after a delay
        setTimeout(updateSelect2Config, 500); // Try again after 0.5 second
      }
    };

    updateSelect2Config();
  }, []);

  const handleClearSelection = (type) => () => {
    filtersStore.selectedSubmittersForType[type] = [];

    /*
     * Select2 doesn't make this easy... wait for state update then close the dropdown.
     * https://select2.org/programmatic-control/methods#closing-the-dropdown
     */
    setTimeout(() => select2Refs.current[type]?.el.select2('close'), 1);
  };

  const renderPopover = () => {
    const { selectedSubmittersForType, handleSelectSubmitterForType } = filtersStore;

    if (!configInitialized) {
      return null; // Render nothing until the config is initialized
    }

    return (
      <>
        {SUBMITTER_TYPES.map((type) => {
          const config = select2Config.current[type];
          if (!config) return null; // Skip rendering if config is not available

          const { dataUrl, resultsKey } = config;

          return (
            <Select2
              key={type}
              id={type}
              data={parseListForSelect2(selectedSubmittersForType[type])}
              onSelect={handleSelectSubmitterForType(type)}
              onUnselect={handleClearSelection(type)}
              options={{
                allowClear: true,
                placeholder: i18n.t(`filter.choose_submitter.${type}`),
                dropdownCssClass: 'filters-select2-dropdown',
                width: '100%',
                ajax: (new csync.Utils.Select2OptionBuilder()).ajax(dataUrl, resultsKey, 'name'),
              }}
              ref={(ref) => (select2Refs.current[type] = ref)}
              value={selectedSubmittersForType[type].map(({ id }) => id)[0]}
            />
          );
        })}
      </>
    );
  };

  const { original: { selectedSubmittersForType } } = filtersStore;
  const submitterNames = SUBMITTER_TYPES.flatMap((type) => {
    return selectedSubmittersForType[type].map(({ name }) => name);
  });

  return (
    <FilterOverlayTrigger
      id="submitter-filter"
      title={i18n.t('filter.submitter')}
      popoverContent={renderPopover()}
      popoverClass="popover-multi-select2"
      onSubmit={onSubmit}
      hints={submitterNames}
      buttonClass="btn-margin-left"
    />
  );
};

SubmitterFilter.propTypes = {
  filtersStore: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default inject('filtersStore')(observer(SubmitterFilter));
