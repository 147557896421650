import { makeAutoObservable, reaction, computed, action } from 'mobx';
import queryString from 'query-string';
import { getLevelsValues, applyDefaultLevelsValues } from '../utils';

class ConditionModel {
  id;
  key;
  optionSetId;
  optionNodeId;
  optionNodeValue;
  leftQingId;
  rightQingId;
  op;
  rightSideType;
  operatorOptions = [];
  value;
  refableQings = [];
  rightQingOptions = [];
  levels = [];
  remove;

  constructor(initialState = {}) {
    makeAutoObservable(this, {
      currTextValue: computed,
      updateLevels: action,
      buildUrl: false,
    });

    Object.assign(this, initialState);

    this.initReactions();
  }

  initReactions() {
    this.disposers = [
      reaction(
        () => this.optionSetId,
        async (optionSetId) => {
          if (optionSetId) {
            await this.updateLevels(null, optionSetId);
          }
        },
      ),
      reaction(
        () => this.leftQingId,
        (leftQingId) => {
          if (leftQingId) {
            const leftQing = this.refableQings.find((qing) => qing.id === leftQingId);
            this.rightQingOptions = this.refableQings.filter((rightQing) => {
              if (leftQing.id === rightQing.id || leftQing.qtypeName === 'select_multiple') return false;
              if (leftQing.textual) return rightQing.textual;
              if (leftQing.numeric) return rightQing.numeric;
              return leftQing.qtypeName === rightQing.qtypeName
                && leftQing.optionSetId === rightQing.optionSetId;
            });
          }
        },
        { fireImmediately: true },
      ),
    ];
  }

  get currTextValue() {
    if (this.optionSetId) {
      const lastIndex = this.levels.length - 1;
      for (let i = lastIndex; i >= 0; i -= 1) {
        const { selected, options } = this.levels[i];
        if (selected != null) {
          const { name = '' } = options.find(({ id }) => selected === id) || {};
          return name;
        }
      }
      return null;
    } else if (this.optionNodeId) {
      return this.optionNodeValue;
    }
    return this.value;
  }

  updateLevels = async (changedNodeId = null, changedOptionSetId = null) => {
    const nodeId = changedNodeId || this.optionNodeId;
    const optionSetId = changedOptionSetId || this.optionSetId;

    if (!optionSetId) {
      return;
    }

    csync.AppInit.loading(true);
    const url = this.buildUrl(nodeId, optionSetId);
    try {
      if (process.env.NODE_ENV === 'test') return;

      const { levels } = await $.ajax(url);
      const oldValues = getLevelsValues(this.levels);
      this.levels = applyDefaultLevelsValues(levels, oldValues);
    } catch (error) {
      console.error('Failed to updateLevels:', error);
    } finally {
      csync.AppInit.loading(false);
    }
  }

  buildUrl = (nodeId, optionSetId) => {
    const params = { node_id: nodeId || 'null', option_set_id: optionSetId };
    const url = csync.AppInit.url_builder.build('condition-form-data', 'option-path');
    return `${url}?${queryString.stringify(params)}`;
  }

  // Dispose reactions if the model is ever destroyed
  dispose() {
    this.disposers.forEach(dispose => dispose());
  }
}

export default ConditionModel;
