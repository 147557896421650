import { makeAutoObservable, reaction } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import ConditionModel from './ConditionFormField/model';

class ConditionSetModel {
  original = {};
  formId;
  namePrefix;
  conditions = [];
  conditionableId;
  conditionableType;
  refableQings = [];
  hide;
  showQingRank = true;
  forceEqualsOp = false;
  forceRightSideLiteral = false;
  rejectionMsgTranslations = {};

  constructor(initialState = {}) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.initialize(initialState);
  }

  initialize(initialState) {
    Object.assign(this, initialState);
    this.original = {
      conditions: cloneDeep(initialState.conditions) || [],
    };

    reaction(
      () => this.original.conditions,
      (conditions) => {
        this.original.conditions = this.prepareConditions(conditions);
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.refableQings,
      (newRefableQings) => {
        if (isEmpty(this.original.refableQings)) {
          this.original.refableQings = cloneDeep(newRefableQings) || [];
        }
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.conditions,
      (conditions) => {
        this.conditions = this.prepareConditions(conditions);
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.hide,
      (hide) => {
        if (!hide) {
          this.handleAddBlankCondition();
        }
      },
      { fireImmediately: true }
    );
  }

  prepareConditions(conditions) {
    let changed = false;
    const newConditions = conditions.map((condition) => {
      if (!(condition instanceof ConditionModel)) {
        changed = true;
        return new ConditionModel({ ...condition, refableQings: this.refableQings });
      }
      if (!condition.refableQings) {
        changed = true;
        condition.refableQings = this.refableQings;
      }
      return condition;
    });
    return changed ? newConditions : conditions;
  }

  resetConditionsFromQings(qings) {
    this.conditions = [];
    qings.forEach(({ id, value, option_node_id: optionNodeId, option_node_value: optionNodeValue }) => {
      this.addCondition(false, {
        leftQingId: id,
        value,
        optionNodeId,
        optionNodeValue,
      });
    });
    this.original.conditions = cloneDeep(this.conditions);
  }

  addCondition(defaultLeftQingToLast = false, params = {}) {
    this.conditions.push(new ConditionModel({
      leftQingId: defaultLeftQingToLast ? this.refableQings[this.refableQings.length - 1]?.id : null,
      refableQings: this.refableQings,
      key: Math.round(Math.random() * 100000000),
      ...params,
    }));
  }

  handleAddBlankCondition() {
    if (this.conditions.length === 0) {
      this.addCondition();
    }
  }

  get conditionCount() {
    return this.conditions.reduce((sum, condition) => sum + (condition.remove ? 0 : 1), 0);
  }

  get isDirty() {
    return !isEqual(cloneDeep(this.original.conditions), this.conditions);
  }
}

export default ConditionSetModel;

