import ReactOnRails from 'react-on-rails';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import process from 'process';

import Filters from '../components/Filters';
import UserAssignmentForm from '../components/UserAssignmentForm';
import ConstraintsFormField from '../components/conditions/ConstraintsFormField';
import DisplayLogicFormField from '../components/conditions/DisplayLogicFormField';
import SkipLogicFormField from '../components/conditions/SkipLogicFormField';

ReactOnRails.register({
  Filters,
  UserAssignmentForm,
  ConstraintsFormField,
  DisplayLogicFormField,
  SkipLogicFormField,
});

const isOnline = !process.env.OFFLINE_MODE || process.env.OFFLINE_MODE === 'false';
if (isOnline && process.env.NODE_ENV !== 'test') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Uncomment to enable Sentry performance monitoring (disabled in favor of Scout).
    // Percentage between 0.0 - 1.0.
    // tracesSampleRate: 1.0,
  });
}

