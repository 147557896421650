import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { inject, observer } from 'mobx-react';

import FilterOverlayTrigger from '../FilterOverlayTrigger/component';
import { i18n } from '../../../i18n';

const CHOICES = [
  { name: i18n.t('common._yes'), value: true, id: 'yes' },
  { name: i18n.t('common._no'), value: false, id: 'no' },
  { name: i18n.t('common.either'), value: null, id: 'either' },
];

const FormFilter = inject('filtersStore')(observer(({ filtersStore, onSubmit }) => {
  const { original: { isReviewed } } = filtersStore;
  const hints = isReviewed == null
    ? null
    : isReviewed ? [i18n.t('common._yes')] : [i18n.t('common._no')];

  const handleChangeIsReviewed = (value) => {
    filtersStore.isReviewed = value;
  };

  const renderPopover = () => (
    <>
      <div>
        <Form.Label>{i18n.t('filter.is_reviewed')}</Form.Label>
      </div>
      <ButtonGroup>
        {CHOICES.map(({ name, value, id }) => (
          <Button
            key={id}
            id={id}
            variant="secondary"
            active={filtersStore.isReviewed === value}
            onClick={() => handleChangeIsReviewed(value)}
          >
            {name}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );

  return (
    <FilterOverlayTrigger
      id="reviewed-filter"
      title={i18n.t('filter.reviewed')}
      popoverContent={renderPopover()}
      onSubmit={onSubmit}
      hints={hints}
      buttonClass="btn-margin-left"
    />
  );
}));

FormFilter.propTypes = {
  filtersStore: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default FormFilter;

