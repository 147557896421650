import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { i18n } from '../../i18n';

function ErrorFallback({ message }) {
  return (
    <div className="alert alert-danger" role="alert">
      {message}
    </div>
  );
}

ErrorFallback.propTypes = {
  message: PropTypes.string,
};

const ErrorBoundary = ({ message = i18n.t('common.jsError'), children }) => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback message={message} />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
