import React from 'react';
import PropTypes from 'prop-types';
import { TrashFill } from 'react-bootstrap-icons';
import { i18n } from '../../../i18n';

/**
 * Models each row of User Assignments consisting of a project and a role.
 */
class UserAssignmentFormField extends React.Component {
  static propTypes = {
    destroy: PropTypes.bool,
    id: PropTypes.string,
    index: PropTypes.number.isRequired,
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    newRecord: PropTypes.bool,
    role: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  constructor(props) {
    super(props);
    const { destroy } = this.props;
    this.state = { destroy };
  }

  projectField = () => {
    const { newRecord, projectId, projectName, index, id } = this.props;

    if (newRecord) {
      return (
        <select
          className="project form-control"
          defaultValue={projectId}
          name={`user[assignments_attributes][${index}][project_id]`}
        >
          {this.projectOptionTags()}
        </select>
      );
    }

    return (
      <div>
        {projectName}
        <input
          name={`user[assignments_attributes][${index}][id]`}
          type="hidden"
          value={id || ''}
        />
      </div>
    );
  };

  projectOptionTags = () => {
    const { projects } = this.props;

    return projects.map((project) => (
      <option
        key={project.id}
        value={project.id}
      >
        {project.name}
      </option>
    ));
  };

  roleOptionTags = () => {
    const { roles } = this.props;

    return roles.map((option) => (
      <option
        key={option}
        value={option}
      >
        {i18n.t(`role.${option}`)}
      </option>
    ));
  };

  projectRoleFields = () => {
    const { role, index } = this.props;

    return (
      <div className="assignment-row">
        <div className="project">
          {this.projectField()}
        </div>
        <div className="role">
          <select
            className="form-control"
            defaultValue={role}
            name={`user[assignments_attributes][${index}][role]`}
          >
            {this.roleOptionTags()}
          </select>
        </div>
        {/* TODO: Improve a11y. */}
        {/* eslint-disable-next-line */}
        <a
          className="remove"
          onClick={this.handleRemoveClick}
        >
          <TrashFill />
        </a>
      </div>
    );
  };

  handleRemoveClick = () => {
    this.setState({ destroy: true });
  };

  render() {
    const { index, id } = this.props;
    const { destroy } = this.state;

    return (
      <div>
        {destroy ? '' : this.projectRoleFields()}
        <input
          name={`user[assignments_attributes][${index}][_destroy]`}
          type="hidden"
          value={destroy}
        />
        <input
          name={`user[assignments_attributes][${index}][id]`}
          type="hidden"
          value={id || ''}
        />
      </div>
    );
  }
}

export default UserAssignmentFormField;
