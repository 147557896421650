import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { inject, observer } from "mobx-react";

import { Search, InfoCircleFill } from "react-bootstrap-icons";
import { i18n } from "../../../i18n";

@inject("filtersStore")
@observer
class AdvancedSearchFilter extends React.Component {
    static propTypes = {
        filtersStore: PropTypes.object,
        renderInfoButton: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
    };

    handleKeyDown = (callback) => (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            callback();
        }
    };

    showSearchHelp = () => {
        var searchHelpModal = new bootstrap.Modal(document.getElementById("search-help-modal"));
        searchHelpModal.show();
    };

    render() {
        const { filtersStore, renderInfoButton, onSubmit } = this.props;
        const { advancedSearchText, handleChangeAdvancedSearch } = filtersStore;
        return (
            <div className="d-flex">
                <Search className="search-icon" />
                <input
                    className="form-control search-str"
                    type="text"
                    name="search"
                    autoComplete="off"
                    value={advancedSearchText}
                    placeholder={i18n.t("filter.search_box_placeholder")}
                    onKeyDown={this.handleKeyDown(onSubmit)}
                    onChange={handleChangeAdvancedSearch}
                />
                <Button variant="secondary" className="btn-apply btn-advanced-search" onClick={onSubmit}>
                    {i18n.t("common.search")}
                </Button>
                {renderInfoButton ? (
                    <InfoCircleFill
                        className="hint"
                        role="button"
                        aria-label={i18n.t("search.help_title")}
                        tabIndex={0}
                        onKeyDown={this.handleKeyDown(this.showSearchHelp)}
                        onClick={this.showSearchHelp}
                    />
                ) : null}
            </div>
        );
    }
}

export default AdvancedSearchFilter;
