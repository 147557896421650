import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { makeObservable, observable, action, computed, reaction, toJS, runInAction } from 'mobx';

import ConditionSetModel from '../conditions/ConditionSetFormField/model';
import { SUBMITTER_TYPES } from './SubmitterFilter/utils';

const initialConditionSetData = toJS(
  new ConditionSetModel({
    namePrefix: 'questioning[display_conditions_attributes]',
    conditionableType: 'FormItem',
    hide: false,
    forceEqualsOp: true,
    forceRightSideLiteral: true,
    showQingRank: false,
  })
);

export const getEmptySubmitterTypeMap = () =>
  SUBMITTER_TYPES.reduce((reduction, type) => {
    reduction[type] = [];
    return reduction;
  }, {});

class FiltersModel {
  original = {};
  conditionSetStore = new ConditionSetModel(initialConditionSetData);
  allForms = [];
  selectedFormIds = [];
  isReviewed = null;
  selectedSubmittersForType = getEmptySubmitterTypeMap();
  advancedSearchText = '';
  startDate = null;
  endDate = null;

  constructor(initialState = {}) {
    makeObservable(this, {
      original: observable,
      conditionSetStore: observable,
      allForms: observable,
      selectedFormIds: observable,
      isReviewed: observable,
      selectedSubmittersForType: observable,
      advancedSearchText: observable,
      startDate: observable,
      endDate: observable,
      selectedFormId: computed,
      isDirty: computed,
      updateRefableQings: action,
      handleSelectForm: action,
      handleSelectSubmitterForType: action,
      handleChangeAdvancedSearch: action,
      handleDateChange: action,
    });

    const { selectedQings, startDate, endDate } = initialState;

    if (startDate) {
      initialState.startDate = moment(startDate);
    }
    if (endDate) {
      initialState.endDate = moment(endDate);
    }

    if (!isEmpty(selectedQings)) {
      this.conditionSetStore.resetConditionsFromQings(selectedQings);
      delete initialState.selectedQings;
    }

    Object.assign(this, initialState);

    Object.assign(this.original, {
      selectedFormIds: cloneDeep(initialState.selectedFormIds) || [],
      isReviewed: initialState.isReviewed == null ? null : initialState.isReviewed,
      selectedSubmittersForType: cloneDeep(initialState.selectedSubmittersForType) || getEmptySubmitterTypeMap(),
      startDate: initialState.startDate,
      endDate: initialState.endDate,
    });

    reaction(
      () => this.selectedFormId,
      async (selectedFormId) => {
        if (this.conditionSetStore.formId !== selectedFormId) {
          runInAction(() => {
            Object.assign(this.conditionSetStore, new ConditionSetModel(initialConditionSetData), {
              original: this.conditionSetStore.original,
            });
          });

          await this.updateRefableQings();
        }
      }
    );
  }

  get selectedFormId() {
    return isEmpty(this.selectedFormIds) ? '' : this.selectedFormIds[0];
  }

  get isDirty() {
    const clean =
      isEqual(this.original.selectedFormIds, this.selectedFormIds) &&
      isEqual(this.original.isReviewed, this.isReviewed) &&
      isEqual(this.original.selectedSubmittersForType, this.selectedSubmittersForType) &&
      isEqual(this.original.startDate, this.startDate) &&
      isEqual(this.original.endDate, this.endDate) &&
      !this.conditionSetStore.isDirty;
    return !clean;
  }


  updateRefableQings = async () => {
    const executeUpdate = async () => {
      if (typeof csync !== "undefined" && csync.AppInit) {
        csync.AppInit.loading(true);
        const url = csync.AppInit.url_builder.build("filter-data", "qings");
        try {
          if (process.env.NODE_ENV === "test") return;
          const qings = await $.ajax({ url, data: { form_ids: this.selectedFormIds } });
          runInAction(() => {
            this.conditionSetStore.refableQings = qings;
          });
        } catch (error) {
          console.error("Failed to updateRefableQings:", error);
        } finally {
          csync.AppInit.loading(false);
        }
      } else {
        setTimeout(executeUpdate, 500);  // Try again after 0.5 second
      }
    };

    executeUpdate();
  };

  handleSelectForm = (event) => {
    this.selectedFormIds = [event.target.value];
  };

  handleSelectSubmitterForType = (type) => (event) => {
    const { id, text: name } = event.params.data;
    this.selectedSubmittersForType[type] = [{ id, name }];
  };

  handleChangeAdvancedSearch = (event) => {
    this.advancedSearchText = event.target.value;
  };

  handleDateChange = ({ startDate, endDate }) => {
    this.startDate = startDate;
    this.endDate = endDate;
  };
}

export default FiltersModel;

